.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.font-face-diablo-h {
  font-family: "Diablo-h";
 }

 .font-face-diablo-l {
  font-family: "Diablo-l";
 }

 .font-face-diablo-l-color {
  font-family: "Diablo-l";
  color: rgb(26, 148, 1);
  z-index: -4;
 }

 .fontsize_setitems {
  z-index: -4;
  font-size: 2vmin;
 }


 .App-body {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  /*justify-content: start;*/
  font-size: calc(2.5vmin);
  color: white;
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav {
  justify-content: center;
  background-color: #ffed9a;  
  border-radius: 0px;
  
}

.d2icon {
  height: 5vmin;
  justify-content: center;
  align-items: center;
}

.d2quest {
  height: 5vmin;
  justify-content: center;
  align-items: center;
}

.d2reddit {
  height: 5vmin;
  justify-content: center;
  align-items: center;
}

.d2discord {
  height: 5vmin;
  justify-content: center;
  align-items: center;
}

.d2nwnicon {
  height: 5vmin;
  justify-content: center;
  align-items: center;
}

.playnow {
  color: #e72222;
  text-decoration: none;
  font-weight: bold;
  /*justify-content: center;*/
  /*align-items: center;*/
  vertical-align:middle;
  
}
.playnow:hover {
  color: #e72222;
}


.playbuttonhover:hover {
  background-color: #bbaf46;
}

.redborder {
  border: 2px solid red;
  width: 60%;margin-left:
   auto;margin-right: auto;
}

.redborder2 {
  border: 2px solid red;
  width: 60%;margin-left:
   auto;margin-right: auto;
}


.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.item1div {
  width: 8%;margin-left:
   auto;margin-right: auto;
position: middle;
justify-content: center;
align-items: center;
}

.item1p {
/*top: 50px;
left: 54px;*/  
text-align: center;
display: block;
z-index: 1;
position: block;
background: rgba(122, 119, 112, 0.1);
}

.item1s {
  /*top: 50px;
  left: 54px;*/  
  font-size: 1.5vmin;
  text-align: center;
  z-index: 1;
  display: block;
  /*background: rgba(122, 119, 112, 0.267);*/
  }

  .zindex {
    /*top: 50px;
    left: 54px;*/  
    text-align: center;
    display: block;
    z-index: 0;
    position: block;
    }

.item1description {
  width: 25%; float: right;
  text-align: left;
  /*margin-left: 5vmin;*/
}

.item2description {
  width: 20%; float: right;
  margin-right: 11.5%;
  /*text-align: left;*/
}

.item_the_gnasher {
  height: 10vmin;
  justify-content: center;
  align-items: center;
}

.item_dimoaks_hew {
  height: 18vmin;
  justify-content: center;
  align-items: center;
}

.item_pluckeye {
  height: 12vmin;
  justify-content: center;
  align-items: center;
}

.item_pelta_lunata {
  height: 9vmin;
  justify-content: center;
  align-items: center;
}

.item_hsarus_iron_fist {
  height: 9vmin;
  justify-content: left;
  align-items: left;
}

.item_hsarus_iron_stay {
  height: 5vmin;
  justify-content: left;
  align-items: left;
}

/*.setitemstitel {
position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
z-index: -4;
}*/

/*.absolute_pos_wrapper {
  position: relative;
  margin-left: 0%;
  margin-right: 0%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -4;
  } */


.playnow_rezize {
width: 25vmin;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
justify-content: center;
text-align: center;
  }




.z_index_b {
  z-index: -2;
  }

  
  /*.playnow_slidepage {
    background-color: rgb(90,22,22);
    } */
    .slide-pane__content{
      background-color: rgb(90,22,22);
    }
    
    .testxxx {
        display: flex;
        flex-direction: column;
        background: rgb(44, 8, 8);
        min-width: 100px;
        height: 100%;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
        /*transition: transform 0.5s;
        will-change: transform;*/
        color:rgb(241, 241, 222);
        text-align: center;
        font-size: 2.48vmin;
        }  
        
        .slide-pane__header {
          font-size: 3.5vmin;
          } 
  
    

.uniqnamecolor {
  /*top: 50px;
  left: 54px;*/  
  color: tan;
  /*background: rgba(122, 119, 112, 0.267);*/
  }

  .setnamecolor {
    /*top: 50px;
    left: 54px;*/  
    color: rgb(26, 148, 1);
    /*background: rgba(122, 119, 112, 0.267);*/
    }